<template>
  <div class="fullPageMenu" id="nav"
       :class="{'active': showNavbar }"

  >



    <div class="banner">
      <img src="https://images.unsplash.com/photo-1453989799106-bbad8d7b5191?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80" alt="image">
    </div>
    <div class="nav"

    >
      <ul>
        <li><a href="#" data-text="Home">Home</a></li>
        <li><a href="#" data-text="About">About</a></li>
        <li><a href="#" data-text="Services">Services</a></li>
        <li><a href="#" data-text="Team">Team</a></li>
        <li><a href="#" data-text="Portfolio">Portfolio</a></li>
        <li><a href="#" data-text="Contact">Contact</a></li>
      </ul>
    </div>
  </div>
  <span class="menuicon" id="toggle"    @click="menuToggle"
        :class="{'active': showNavbar }"

  ></span>
</template>

<script>
export default {
  name: "MenuNr3",


  data() {

    return {

      showNavbar : true


    }

  },


methods: {

    menuToggle(){
      this.showNavbar = !this.showNavbar;
    }

}
}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@400;500;600;700&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Teko', sans-serif;
}


.fullPageMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #111;
  transition: 1s;
}

.fullPageMenu.active {
  top: -100%;
}

.fullPageMenu .banner {
  position: relative;
  width: 600px;
  height: 100%;
}

.fullPageMenu .banner img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fullPageMenu .nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.fullPageMenu .nav ul {
  position: relative;
}

.fullPageMenu .nav ul li {
  position: relative;
  list-style: none;
  padding: 0 20px;
  margin: 5px 0;
  overflow: hidden;
  display: table;
}

.fullPageMenu .nav ul li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ff0047;
  transition: transform 0.5s ease-in-out;
  transform: scaleY(0);
  transform-origin: bottom;
}

.fullPageMenu .nav ul li:hover:before {
  transition: transform 0.5s ease-in-out;
  transform: scaleY(1);
  transform-origin: top;
}

.fullPageMenu .nav ul li a {
  position: relative;
  color: #84ae04!important;
  text-decoration: none;
  font-size: 4em;
  font-weight: 700;
  line-height: 1em;
  display: inline-block;
  text-transform: uppercase;
  transition: 0.5s ease-in-out;
}

.fullPageMenu .nav ul li a::before {
  content: attr(data-text);
  position: absolute;
  bottom: -100%;
  left: 0;
  color: #fff;
}

.fullPageMenu .nav ul li:hover a {
  transform: translateY(-100%);
  color: #fff;
}

.menuicon {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background: #fff url(https://i.postimg.cc/k4rb3zpp/pngwave.png);
  background-size: 40px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 10px;
}

.menuicon.active {
  background: #fff url(https://i.postimg.cc/25t1dMY5/pngegg-1.png);
  background-size: 40px;
  background-repeat: no-repeat;
  background-position: 10px;
}

@media(max-width:991px) {
  .banner {
    display: none;
  }
}
</style>